import Swiper from 'swiper';
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import AOS from 'aos';

document.addEventListener('DOMContentLoaded', () => {

    let lastScrollTop = 0;
    let ticking = false;

    window.addEventListener('scroll', function () {
        if (!ticking) {
            window.requestAnimationFrame(function () {
                const divs = document.querySelectorAll('.offset-background');
                if (!divs.length) return;

                let currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
                divs.forEach((div) => {
                    const divTop = div.getBoundingClientRect().top + window.scrollY;
                    const divBottom = divTop + div.offsetHeight;
                    const divInViewport = (div.getBoundingClientRect().top < window.innerHeight) && (div.getBoundingClientRect().bottom > 0);

                    if (currentScroll > lastScrollTop && divInViewport) {
                        // Scrolling down and div is in viewport
                        div.classList.add('remove-padding');
                        // div.style.margin = '0';
                    } else if (currentScroll < lastScrollTop && divInViewport) {
                        // Scrolling up and div is in viewport
                        div.classList.remove('remove-padding');
                        // div.style.margin = '32px';
                    }
                });
                lastScrollTop = currentScroll <= 0 ? 0 : currentScroll; // For Mobile or negative scrolling
                ticking = false;
            });
            ticking = true;
        }
    });

    AOS.init({
        duration: 1000,
        easing: 'ease',
        once: true,
    });

    $(document).foundation();

    const header = document.querySelector('.site-header');
    const nav = document.querySelector('.main-menu');
    const menuBtn = document.querySelector('.menu-btn');
    const lines = document.querySelectorAll('.menu-btn .line');


    // Toggle navigation menu
    menuBtn.addEventListener('click', () => {
        nav.classList.toggle('open');
        header.classList.toggle('open');
        lines.forEach((line, index) => {
            line.classList.toggle('line-cross');
            if (index === 1) line.classList.toggle('line-fade-out');
        });
    });

    const ul = document.querySelectorAll("nav#site-navigation ul li ul.children");
    const openSearchBtn = document.querySelector('.open-search-from');
    const deleteSearchBtn = document.querySelector('#deleteSearch');
    const videoContainer = document.querySelector('.video-container');

    // Check if an element is scrolled into view
    const isScrolledIntoView = elem => {
        const docViewTop = window.scrollY;
        const docViewBottom = docViewTop + window.innerHeight + 200;
        const elemTop = elem.offsetTop;
        const elemBottom = elemTop + elem.offsetHeight;
        return elemBottom <= docViewBottom && elemTop >= docViewTop;
    };

    // Handle sticky header on scroll
    const checkScroll = () => {
        const header = document.querySelector('.site-header');
        window.scrollY > 30 ? header.classList.add('scrolled') : header.classList.remove('scrolled');
    };

    // Run the scroll check on page load and on scroll events
    checkScroll();
    window.addEventListener('scroll', checkScroll);

    // Mark parents with children in the navigation
    ul.forEach(ul => {
        ul.parentNode.classList.add('hasChildren');
        const expandLink = "<a href='javascript:void(0)' class='expandChild' title='Child item'></a>";
        ul.parentNode.querySelector('a').insertAdjacentHTML('beforeend', expandLink);
    });

    // Close navigation if clicked outside
    document.addEventListener('click', e => {
        if (!nav.contains(e.target)) nav.classList.remove('open-nav');
    });

    // // Open search bar
    // openSearchBtn.addEventListener('click', () => {
    //     document.querySelector('.top-search').classList.add('open-search');
    //     document.body.classList.add('search-main');
    // });
    //
    // // Close search bar
    // deleteSearchBtn.addEventListener('click', () => {
    //     document.querySelector('.top-search').classList.remove('open-search');
    //     document.body.classList.remove('search-main');
    // });

    // Video play functionality
    if (videoContainer) {
        const playButton = videoContainer.querySelector('.play-button');
        const iframe = videoContainer.querySelector('iframe');
        playButton?.addEventListener('click', () => {
            playButton.style.display = 'none';
            iframe.style.display = 'block';
            iframe.src += "?autoplay=1";
        });
    }

    jQuery('.footer-label').click(function ($) {
        jQuery(this).closest('.footer-menu').find('.footer-container ul').slideToggle();
        jQuery(this).closest('.footer-menu').find('i').toggleClass('open');
    });

    var gallerySlider = new Swiper(".gallery-slider", {
        direction: "horizontal",
        slidesPerView: 1.1,
        spaceBetween: 20,
        breakpoints: {
            1022: {
                slidesPerView: 2.5,
            },
        },
        keyboard: {
            enabled: true,
        },
    });

    var projectSlider = new Swiper(".project-swiper", {
        direction: "horizontal",
        slidesPerView: 1.1,
        spaceBetween: 20,
        breakpoints: {
            1022: {
                slidesPerView: 2.5,
            },
        },
        keyboard: {
            enabled: true,
        },
    });

    var cardSlider = new Swiper(".card-swiper", {
        direction: "horizontal",
        slidesPerView: 1.1,
        spaceBetween: 20,
        breakpoints: {
            1022: {
                slidesPerView: 3.5,
            },
        },
        keyboard: {
            enabled: true,
        },
    });

    const swiper = new Swiper('.logo-swiper', {

        modules: [Autoplay],
        slidesPerView: 2, // Number of logos visible at a time
        spaceBetween: 0, // Space between logos
        loop: true, // Enable infinite loop
        breakpoints: {
            1022: {
                slidesPerView: 5,
                },
        },
        autoplay: {
            delay: 0, // No delay for continuous movement
            disableOnInteraction: false,
        },
        speed: 7500, // Speed of the scrolling animation in milliseconds
    });

});

